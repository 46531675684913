import { FunctionComponent, useContext, useState } from "react";
import Image from "next/image";
import logo from "../public/assets/logo.png";
import navToggle from "../public/assets/navToggle.svg";
import navClose from "../public/assets/navClose.svg";
import { useQueryClient } from "react-query";
import { useMemo } from "react";
import { Auth } from "aws-amplify";
import { useQuery } from "react-query";
import Router from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import Link from "next/link";
import { CurrentBuild, ModelContext } from "./Layout";

interface NavbarProps {}

const Navbar: FunctionComponent<NavbarProps> = () => {
  const { setCurrentBuild, currentBuild } = useContext(ModelContext);
  const queryClient = useQueryClient();
  const cognitoUserQueryKey = useMemo(() => ["cognito", "user"], []);
  const { data: user } = useQuery(cognitoUserQueryKey, () =>
    Auth.currentAuthenticatedUser().finally(() => {
      setLoggedIn(true);
    })
  );

  const [navIsOpen, setNavIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <nav className="fixed w-full left-0">
      <div className="flex justify-between items-center py-6 px-10">
        <Link
          href={{
            pathname: "/",
          }}
          className="flex"
        >
          <Image src={logo} alt="Dvele logo image" width={150} priority />
        </Link>
        <a
          aria-current={"page"}
          onClick={() => setNavIsOpen(true)}
          className="cursor-pointer "
        >
          <Image src={navToggle} alt="Nav toggle" width={26} />
        </a>
      </div>
      <div className={navIsOpen ? "nav-desktop active" : "nav-desktop"}>
        <div className="p-6 flex flex-col h-full">
          <div className="flex justify-end">
            <a
              aria-current={"page"}
              className="self-end cursor-pointer "
              onClick={() => setNavIsOpen(false)}
            >
              <Image src={navClose} alt="Nav Close" width={22} />
            </a>
          </div>
          <div className="pt-6 grow flex flex-col justify-between">
            <ul className="nav-links pt-4">
              <li>
                <Link
                    href={{
                      pathname: "/",
                    }}
                    onClick={() => {
                      setNavIsOpen(false);
                    }}
                >
                  Catalog
                </Link>
              </li>
              {loggedIn && user ? (
                <div>
                  <li>
                    {user?.attributes?.given_name && (
                      <h2>Hi, {user.attributes.given_name}</h2>
                    )}
                  </li>
                  <li>
                    <Link
                      href={{
                        pathname: "/builds",
                      }}
                      onClick={() => {
                        setNavIsOpen(false);
                      }}
                    >
                      My Builds
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={{
                        pathname: "/settings",
                      }}
                      onClick={() => {
                        setNavIsOpen(false);
                      }}
                    >
                      Settings
                    </Link>
                  </li>
                </div>
              ) : (
                <li>
                  <span className="text-black font-bold text-xl cursor-pointer" onClick={() => {
                    if (Router.pathname === "/design/[slug]") {
                      setNavIsOpen(false);
                      Router.push({
                        pathname: '/login',
                        query: { from: `/design/${encodeURIComponent(currentBuild.model?.slug as string)}`}
                        })
                      }
                    else {
                      setNavIsOpen(false);
                      Router.push({
                        pathname: '/login',
                        query: { from: Router.pathname }
                      })
                    }
                  }}>
                Log in
              </span>
                </li>
              )}

              <li>
                <a
                  href="https://www.dvele.com/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    setNavIsOpen(false);
                  }}
                >
                  Dvele.com
                </a>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  style={{ fontSize: 14, marginRight: 4 }}
                />
              </li>
              <li>
                <Link
                  href={{
                    pathname: "/Contact",
                  }}
                  onClick={() => {
                    setNavIsOpen(false);
                  }}
                >
                  Contact
                </Link>
              </li>

              {loggedIn && user && (
                <li>
                  <a
                    href="#"
                    onClick={async () => {
                      await Auth.signOut();
                      queryClient.removeQueries(cognitoUserQueryKey);
                      setLoggedIn(false);
                      Router.push("/");
                    }}
                  >
                    Log Out
                  </a>
                </li>
              )}
            </ul>
            <ul className="terms-policies-links">
              <li>
                <Link
                  href={{
                    pathname: "/privacy",
                  }}
                  onClick={() => {
                    setNavIsOpen(false);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  href={{
                    pathname: "/terms",
                  }}
                  onClick={() => {
                    setNavIsOpen(false);
                  }}
                >
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
