import { faMessageDots } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

export function Contact() {
  const [showButton, setShowButton] = useState("hide");
  const router = useRouter();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setShowButton(position >= 500 ? "show" : "hide");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Link
        href={{
          pathname: "/Contact",
        }}
        className={`fixed bottom-14 left-14 bg-accent w-16 h-16 rounded-full flex justify-center items-center z-10 contact-button ${showButton} ${
          !router.pathname.includes("design") ? "homepage" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={faMessageDots}
          style={{ fontSize: 24, color: "white" }}
        />
      </Link>
    </>
  );
}
